<template>
  <div>
    <page-title></page-title>
    <b-card class="mt-1" no-body>
			<b-card-body>
        <!-- <div id="container">
          <pdf src="/scds/carbon_template.pdf" :page="6"></pdf>
        </div> -->
        <iframe style="width: 100%; height: 80vh;" src="/scds/carbon_report.pdf" frameborder="0" ></iframe>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import PageTitle from "@/views/layout/PageTitle";
import pdf from 'vue-pdf'

export default {
  name: 'carbon-emission-report',
  components: {
    PageTitle,
    pdf
  },
  data(){
    return{

    };
  },
}
</script>

<style scoped lang="scss">
#container{
  overflow: auto;
  height: 78vh;
  width: 60%;
} 
</style>